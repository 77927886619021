export default {
    data() {
        return {
            aplicacoesUrl: ["http", "https", ".", ".coop", ".br"]
        };
    },
    methods: {
        buscaColaboradores(str) {
            str = str.trim();

            if (str == "") return null;

            if (str.includes(".") || str.includes("-") || (!isNaN(str) && str.length > 3)) return "loginUsuario";

            if (!isNaN(str)) return "codigoUnimed";

            return "nome";
        },

        buscaJuridicos(str) {
            str = str.trim();

            if (str == "") return null;

            if (str.includes(".") || str.includes("/") || (!isNaN(str) && str.length > 3)) return "numeroDocumento";

            if (!isNaN(str)) return "codigoUnimed";

            return "nome";
        },

        buscaAplicacoes(str) {
            str = str.trim();

            if (str == "") return null;

            const url = this.aplicacoesUrl.some(reg => str.includes(reg));

            return url ? "url" : "nome";
        },

        buscaContasServicos(str) {
            str = str.trim();

            return str == "" ? "" : "aplicacao";
        },

        buscaAdministradores(str) {
            //Todo
            console.log(str);
        },

        buscaWebService(str) {
            //Todo
            console.log(str);
        }
    }
};
