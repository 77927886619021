import Axios from "axios";

var UsuarioServiceMixin = {
    methods: {
        getFoto(uuid) {
            return Axios.get(
                this.CriarUrl({ resource: `api/usuario/${uuid}/foto` }),
                { responseType: "blob" },
                { withCredentials: true }
            );
        },

        deleteFoto(uuid) {
            return Axios.delete(this.CriarUrl({ resource: `api/usuario/${uuid}/foto` }), { withCredentials: true });
        },

        patchAdicionarFoto(uuid, arquivo) {
            return Axios.patch(
                this.CriarUrl({ resource: `api/usuario/${uuid}/foto` }),
                this.CriarFormData({ arquivo }),
                { withCredentials: true }
            );
        },

        getDadosBasicosMenu() {
            return Axios.get(this.CriarUrl({ resource: `api/usuario/obtem-dados-menu-usuario` }), {
                withCredentials: true
            });
        },

        putDadosBasicoMenu(body) {
            return Axios.put(this.CriarUrl({ resource: `api/usuario/alterar-dados-menu-usuario` }), body, {
                withCredentials: true
            });
        },

        putDadosBasico(body) {
            return Axios.put(this.CriarUrl({ resource: `api/usuario/alterar-dados-basicos` }), body, {
                withCredentials: true
            });
        },

        getTermoAceito() {
            return Axios.get(this.CriarUrl({ resource: `api/termo-usos/termo-aceito-usuario` }), {
                withCredentials: true
            });
        }
    }
};

export default UsuarioServiceMixin;
