import Vue from "vue";

import Router from "vue-router";
import RouterMultiGuard from "vue-router-multiguard";

const Login = () => import(/* webpackChunkName: "Login" */ "../views/Login.vue");
const RedefinirSenha = () => import(/* webpackChunkName: "RedefinirSenha" */ "../views/RedefinirSenha.vue");
const GerarNovaSenha = () => import(/* webpackChunkName: "GerarNovaSenha" */ "../views/GerarNovaSenha.vue");

const Home = () => import(/* webpackChunkName: "Home" */ "../views/Home.vue");
const Aplicacoes = () => import(/* webpackChunkName: "Aplicacoes" */ "../views/Aplicacoes.vue");
const GerenciarAplicacoes = () =>
    import(/* webpackChunkName: "GerenciarAplicacoes" */ "../views/GerenciarAplicacoes.vue");
const Auditoria = () => import(/* webpackChunkName: "Auditoria" */ "../views/Auditoria.vue");
const GerenciarUsuarios = () => import(/* webpackChunkName: "Auditoria" */ "../views/GerenciarUsuarios.vue");
const ContaServico = () => import(/* webpackChunkName: "ContaServico" */ "../views/ContaServico.vue");

import { isLoggedIn, canEnter, openManual } from "./RouterMiddlewareMixin";

Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "default",
            component: Login,
            props: true
        },
        {
            path: "/manual",
            beforeEnter: RouterMultiGuard([openManual])
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            props: true
        },
        {
            path: "/recuperar-senha",
            name: "recuperar-senha",
            component: RedefinirSenha,
            props: true
        },
        {
            path: "/gerar-nova-senha",
            name: "gerar-nova-senha",
            component: GerarNovaSenha,
            props: true
        },
        {
            path: "/home",
            name: "home",
            component: Home,
            props: true,
            beforeEnter: RouterMultiGuard([isLoggedIn])
        },
        {
            path: "/aplicacoes",
            name: "aplicacoes",
            component: Aplicacoes,
            props: true,
            beforeEnter: RouterMultiGuard([isLoggedIn, canEnter])
        },
        {
            path: "/gerenciarAplicacoes",
            name: "gerenciarAplicacoes",
            component: GerenciarAplicacoes,
            props: true,
            beforeEnter: RouterMultiGuard([isLoggedIn, canEnter])
        },
        {
            path: "/auditoria",
            name: "auditoria",
            component: Auditoria,
            props: true,
            beforeEnter: RouterMultiGuard([isLoggedIn, canEnter])
        },
        {
            path: "/gerenciarUsuarios",
            name: "gerenciarUsuarios",
            component: GerenciarUsuarios,
            props: true,
            beforeEnter: RouterMultiGuard([isLoggedIn, canEnter])
        },
        {
            path: "/contaServico",
            name: "contaServico",
            component: ContaServico,
            props: true,
            beforeEnter: RouterMultiGuard([isLoggedIn, canEnter])
        }
    ]
});
