export default {
    state: {
        modalAlterarSenha: false,
        modalDados: false
    },
    getters: {
        mostrarModalAlterarSenha: state => state.modalAlterarSenha,
        mostrarModalDados: state => state.modalDados
    },
    mutations: {
        switchModalAlterarSenha: function(state, boolean) {
            state.modalAlterarSenha = boolean;
        },
        switchModalDados: function(state, boolean) {
            state.modalDados = boolean;
        }
    }
};
