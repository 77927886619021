import sessaoMixin from "../mixins/SessaoUsuarioMixin";

export function isLoggedIn(_to, _from, next) {
    const user = sessaoMixin.methods.getSession();
    const token = sessaoMixin.methods.getAuthToken();

    if (!user && !token) next("/login");
    else next();
}

export function canEnter(to, from, next) {
    const profile = sessaoMixin.methods.getSession().usuarioUnimed.perfil.nome;

    switch (profile) {
        case "Colaborador":
            if (to.name == "aplicacoes") next();
            break;

        case "Auditor":
            if (to.name == "auditoria") next();
            break;

        case "Admin":
        case "Conta de Serviço":
            if (to.name == "aplicacoes" || to.name == "gerenciarUsuarios") next();
            break;

        default:
            next();
            break;
    }
}

export function openManual() {
    window.open("/assets/manual/manuais.zip");
    return;
}
