var LogicaTabelaMixin = {
    methods: {
        getArgumentoMixin(argumentoAdicional = {}) {
            const argumentoPadraoTabela = {
                direcaoOrdenacao: this.ordem,
                ordenacao: this.campoOrdenado,
                pagina: this.pagina,
                tamanhoPagina: this.itensPorPagina
            };

            return { ...argumentoPadraoTabela, ...argumentoAdicional };
        },

        trocaElementoPaginaMixin(item) {
            this.itensPorPagina = item;
            this.paginar();
        },

        filtrarMixin(filtro) {
            this.filtro = filtro;
            this.paginar();
        },

        trocaPaginaMixin(item) {
            this.pagina = item.pagina;
            this.itensPorPagina = item.itensPorPagina;
            this.paginar();
        }
    }
};

export default LogicaTabelaMixin;
