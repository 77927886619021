import Axios from "axios";

export default {
    data() {
        return {};
    },
    methods: {
        setDadosSessao(sessao) {
            this.$dadosSessao = sessao;
        },

        setUnimedUsuario(unimed) {
            this.$dadosSessao.usuarioUnimed = unimed;
            this.$dadosSessao.clientId = this.$appConfig.config.CLIENT_ID;
            localStorage.setItem("session", JSON.stringify(this.$dadosSessao));
        },

        setAuthToken(tokenData) {
            const accessToken = tokenData.access_token;

            this.$dadosSessao.userId = tokenData.user_id;
            localStorage.setItem("Authorization", accessToken);
        },

        setNewAuthToken(authToken){
            localStorage.setItem("Authorization", authToken);
        },

        getSession() {
            return JSON.parse(localStorage.getItem("session"));
        },

        getAuthToken() {
            return localStorage.getItem("Authorization");
        },

        getDadosSessao() {
            return Axios.get(this.CriarUrl({ resource: "api/usuario/obtem-sessao" }), { withCredentials: true });
        },

        getTermoUsoAceito() {
            return Axios.get(this.CriarUrl({ resource: "api/usuario/termo-uso-aceito" }), { withCredentials: true });
        },

        getTermoUso() {
            return Axios.get(this.CriarUrl({ resource: "api/termo-usos" }), { withCredentials: true });
        },

        putTermoUso(data) {
            return Axios.put(this.CriarUrl({ resource: "api/termo-usos/aceite-termo-uso" }), data, {
                withCredentials: true
            });
        },

        refreshToken() {
            return Axios.post(
                this.CriarUrl({ resource: "api/token" }),
                { grant_type: "refresh_token" },
                { withCredentials: true }
            );
        },

        clearStorage() {
            localStorage.clear();
        },

        canView(app) {
            let session = this.getSession()
            const profile = session.usuarioUnimed.perfil.nome;
            const rota = app.rota;

            var result = false;
            switch (profile) {
                case "Colaborador":
                    rota == "aplicacoes" ? (result = true) : null;
                    break;

                case "Auditor":
                    rota == "auditoria" ? (result = true) : null;
                    break;

                case "Admin":
                    rota == "aplicacoes" || rota == "gerenciarUsuarios" ? (result = true) : null;
                    break;
                case "Conta de Serviço":
                    rota == "aplicacoes" || rota == "gerenciarUsuarios" ? (result = true) : null;
                    break;

                default:
                    result = true;
                    break;
            }

            if (rota == "termos") result = true;

            return result;
        }
    }
};
