<template>
    <div class="header">
        <p class="fonte-primaria nome">{{ usuario.nomeUsuario }}</p>
        <p class="fonte-secundaria dados">{{ usuario.email }}</p>
        <p class="fonte-secundaria dados">{{ formatarLogin }}</p>
        <p class="fonte-secundaria link">
            <span @click="$store.commit('switchModalAlterarSenha', true)">alterar senha |</span>
            <span @click="$store.commit('switchModalDados', true)">alterar dados</span>
        </p>
    </div>
</template>

<script>
import { mask } from "ke-the-mask";
export default {
    directives: {
        mask
    },
    props: {
        dadosSessao: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            usuario: this.dadosSessao
        };
    },
    computed: {
        formatarLogin() {
            if (this.usuario.login.length <= 8) {
                return this.usuario.login.replace(/([0-9a-zA-Z]{7})([0-9a-zA-Z]){1}/, "$1-$2");
            } else if (this.usuario.login.length <= 11) {
                return this.usuario.login
                    .replace(/(\d{3})(\d)/, "$1.$2")
                    .replace(/(\d{3})(\d)/, "$1.$2")
                    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
            } else {
                return this.usuario.login.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }
        }
    },
    watch: {
        dadosSessao() {
            this.usuario = this.dadosSessao;
        }
    }
};
</script>

<style lang="scss">
.header {
    margin-left: 50px;
    box-sizing: border-box;

    .nome {
        float: left;
        width: 100%;
        color: #00975c;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 2%;
    }

    .dados {
        line-break: anywhere;
        text-align: left;
        line-height: 18px;
        letter-spacing: 0px;
        color: #60656c;
        opacity: 1;
    }

    .link {
        padding-top: 1%;
        text-align: left;
        letter-spacing: 0px;
        color: #00975c;
        opacity: 1;
    }
}
</style>
