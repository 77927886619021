import { finallyPolyfill } from './polyfill/polyfill'
finallyPolyfill();
import UnimedComponentes from "unimed-components";
import "unimed-components/dist/unimed-components.css";
import Notifications from "vue-notification";

import 'whatwg-fetch';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./validations/Validations";
import BaseMixin from "./mixins/BaseMixin";
import store from "./store/BaseStore";

BaseMixin.Start();

Vue.use(Notifications);
Vue.use(UnimedComponentes);
Vue.config.productionTip = false;

let globalData = new Vue({
    data: {
        $appConfig: {},
        $dadosSessao: {}
    }
});

Vue.mixin({
    computed: {
        $appConfig: {
            get: function() {
                return globalData.$data.$appConfig;
            },
            set: function(newConfig) {
                globalData.$data.$appConfig = newConfig;
            }
        },
        $dadosSessao: {
            get: function() {
                return globalData.$data.$dadosSessao;
            },
            set: function(newConfig) {
                globalData.$data.$dadosSessao = newConfig;
            }
        }
    }
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
