import Axios from "axios";

var PessoaJuridicaServiceMixin = {
    methods: {
        getJuridicos(args, mock = false) {
            if (mock) {
                //URL do mock
                var url = "https://37536626-bcab-46af-bb11-3f43309a4177.mock.pstmn.io/api/juridicos/filtra";
                return Axios.get(url);
            }

            return Axios.get(
                this.CriarUrl({
                    resource: "api/juridicos/filtra",
                    params: args
                }),
                { withCredentials: true }
            );
        },

        getJuridicoPeloId(id) {
            return Axios.get(this.CriarUrl({ resource: `api/juridicos/${id}` }), { withCredentials: true });
        },

        async putAtualizarJuridico(id, body) {
            return Axios.put(this.CriarUrl({ resource: `api/juridicos/${id}` }), body, { withCredentials: true });
        },

        postCadastrarJuridico(body) {
            return Axios.post(this.CriarUrl({ resource: `api/juridicos/` }), body, { withCredentials: true });
        },

        getAllJuridicos(args) {
            return Axios.get(
                this.CriarUrl({ 
                    resource: "api/juridicos/combo",
                    params: args                
                }),
                { withCredentials: true }
            );
        },

        putImportarCargaPessoaJuridica(data) {
            return Axios.put(this.CriarUrl({ resource: `api/juridicos/importar` }), data, { withCredentials: true });
        },
        deleteInativarJuridico(id) {
            return Axios.delete(
                this.CriarUrl({
                    resource: `api/juridicos/${id}`
                }),
                { withCredentials: true }
            );
        }
    }
};

export default PessoaJuridicaServiceMixin;
