import Vue from "vue";

import SessaoUsuarioMixin from "./SessaoUsuarioMixin";

import LogicaTabelaMixin from "./LogicaTabelaMixin";
import FiltroMixin from "./FiltroMixin";

import UrlBuilderServiceMixin from "./services/UrlBuilderServiceMixin";
import DominiUnimedBrasil from "./services/dominios/UnimedServiceMixin";
import BaseServiceMixin from "./services/BaseServiceMixin";

class BaseMixin {
    Start() {
        Vue.mixin({
            mixins: [
                UrlBuilderServiceMixin,
                SessaoUsuarioMixin,
                DominiUnimedBrasil,
                BaseServiceMixin,
                LogicaTabelaMixin,
                FiltroMixin
            ]
        });
    }
}
export default new BaseMixin();
