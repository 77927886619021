// Vuex Import
import Vue from "vue";
import Vuex from "vuex";

// Modules Imports
import AplicacoesPapeis from "./AplicacoesPapeis";
import DadosUsuario from "./DadosUsuario";
import DadosModais from "./ControleModais";
import LoadingStore from './LoadingStore'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AplicacoesPapeis,
        DadosUsuario,
        DadosModais,
        LoadingStore
    }
});
