<template>
    <div>
        <ul class="lista-informacoes">
            <li class="dropdown-item"><p @click="$emit('click_termos')">Ver termos de uso</p></li>
            <li class="dropdown-item"><p @click="logout">Sair do Portal</p></li>
        </ul>
    </div>
</template>

<script>
import LoginServiceMixin from "../../login/shared/services/LoginServiceMixin";

export default {
    mixins: [LoginServiceMixin],

    methods: {
        async logout() {
            await this.postLogout().finally(() => {
                this.$router.push("/login");
            });
        }
    }
};
</script>

<style lang="scss">
.lista-informacoes {
    float: left;
    width: 100%;
    margin-top: 8px;
    box-sizing: border-box;

    .dropdown-item {
        float: left;
        width: 100%;
        border-top: 1px solid #f1f2f1;

        p {
            font-size: 14px;
            padding: 13px 0;
            padding-left: 16px;
            color: #00975c;

            &:hover {
                background-color: #00975c;
                color: #fff;
            }
        }

        &:last-of-type {
            p:hover {
                border-radius: 0 0 8px 8px;
            }
        }
    }
}
</style>
