var UrlBuilderServiceMixin = {
    data() {
        return {
            url: "",
            argumentos: ""
        };
    },

    methods: {
        CriarUrl(args) {
            this.argumentos = args;

            return this.ConfigurarUrl().ConfigurarOptions();
        },

        CriarUrlAuditoria(args) {
            this.argumentos = args;

            return this.ConfigurarUrlAuditoria().ConfigurarOptions();
        },

        CriarFormData(dados) {
            let formData = new FormData();

            for (const key in dados) {
                formData.append(key, dados[key]);
            }

            return formData;
        },

        ConfigurarUrl() {
            this.url = `${this.$appConfig.config.API}`;

            if (this.url.charAt(this.url.length - 1) !== "/") {
                this.url += "/";
            }

            if (this.argumentos.resource.charAt(0) === "/") {
                this.argumentos.resource = this.argumentos.resource.substring(1);
            }

            this.url += this.argumentos.resource;

            return this;
        },

        ConfigurarUrlAuditoria() {
            this.url = `${this.$appConfig.config.API_AUDITORIA}`;

            if (this.url.charAt(this.url.length - 1) !== "/") {
                this.url += "/";
            }

            if (this.argumentos.resource.charAt(0) === "/") {
                this.argumentos.resource = this.argumentos.resource.substring(1);
            }

            this.url += this.argumentos.resource;

            return this;
        },

        ConfigurarOptions() {
            var params = "";

            for (const key in this.argumentos.params) {
                if (key in this.argumentos.params) {
                    const item = this.argumentos.params[key];
                    if (item !== undefined) {
                        params += "&" + key + "=" + item;
                    }
                }
            }

            if (params != "") {
                if (params.charAt(0) === "&") {
                    params = params.substring(1);
                }

                if (this.url.indexOf("?") === -1) {
                    this.url += "?";
                }

                this.url += params;
            }

            return this.url;
        }
    }
};

export default UrlBuilderServiceMixin;
