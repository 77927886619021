import Axios from "axios";

import SessaoUsuarioMixin from "../SessaoUsuarioMixin";

var servicePartial = {
    mixins: [SessaoUsuarioMixin],
    data() {
        return {
            requestNotUseBase: [
                "/api/token",
                "/api/usuario/obtem-email-parcial",
                "/api/account/start-password-recovery",
                "/api/account/finish-password-recovery",
                "/api/auditorias"
            ],

            requestNotUseUnimedAppX: [
                "/api/usuario/obtem-sessao",
                "/api/termo-usos/aceite-termo-uso",
                "/regex/cadastro-usuario-senha",
                "/account/change-password"
            ],

            requestNotUseAuthorization: [
                "/api/unimeds",
                "/api/dominio/todos-estados",
                "/api/dominio/tipos-operacoes",
                "/api/dominio/tipos-colaborador",
                "/api/dominio/tipos-conselho-profissinal",
                "/api/dominio/tipos-documento",
                "/api/regex/cadastro-usuario-senha",
                "/api/auditorias"
            ]
        };
    },
    methods: {
        async StartBaseService() {
            await this.StartRequest();
            await this.StartResponse();
        },

        async StartRequest() {
            const that = this;

            Axios.interceptors.request.use(
                request => {
                    request.withCredentials = true;

                    const useUnimedAppX = that.CheckUseCustomHeader(request, that.requestNotUseUnimedAppX);
                    const useAuthorization = that.CheckUseCustomHeader(request, that.requestNotUseAuthorization);
                    const session = that.getSession();

                    if (useUnimedAppX) {
                        const unimedId = session.usuarioUnimed.unimed.codigo;
                        const clientId = session.clientId;

                        request.headers["X-UNIMED-APP"] = `${unimedId};${clientId}`;
                    }

                    if (useUnimedAppX || useAuthorization) {
                        const authToken = that.getAuthToken();

                        request.headers["Authorization"] = `Bearer ${authToken}`;
                    }

                    request.headers["X-PORTAL-ID"] = this.$appConfig.config.CLIENT_ID_LOGIN;

                    return request;
                },
                err => {
                    return Promise.reject(err);
                }
            );
        },

        async StartResponse() {
            const that = this;

            Axios.interceptors.response.use(
                response => {
                    if (response.headers["x-authorization-new"]) {
                        that.setNewAuthToken(response.headers["x-authorization-new"]);
                    }
                    return response;
                },
                async err => {
                    if (err.response) {
                        const statusCode = err.response.status;
                        var response = err.response.data;

                        var codigo;
                        var descricao;

                        if (response instanceof Blob) {
                            response = that.blobToString(response);
                        }

                        if (response.erro.codigo || response.erro.descricao) {
                            response = response.erro;

                            codigo = response.codigo;
                            descricao = response.descricao;
                        } else {
                            codigo = statusCode;
                            descricao = `
                            Error: ${response.error} \n
                            Message: ${response.message} \n
                            Path: ${response.path}`;
                        }

                        const errObj = {codigo, descricao};

                        that.montarErroModal(errObj);
                    }
                    this.$store.commit("setLoading", false);

                    return Promise.reject(err);
                }
            );
        },

        CheckUseCustomHeader(request, header) {
            const url = request.url;
            const arr = header.concat(this.requestNotUseBase);

            return arr.every(str => !url.includes(str))
        },

        blobToString(blob) {
            var url, xmlHttp;

            url = URL.createObjectURL(blob);
            xmlHttp = new XMLHttpRequest();
            xmlHttp.open("GET", url, false);
            xmlHttp.send();

            URL.revokeObjectURL(url);

            return JSON.parse(xmlHttp.responseText);
        }
    }
};

export default servicePartial;
