import Axios from "axios";

var RedefinirSenhaServiceMixin = {
    methods: {
        getRegrasValidacao() {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/regex/cadastro-usuario-senha"
                })
            );
        },

        finalizarRecuperacaoSenha(data) {
            return Axios.post(this.CriarUrl({ resource: "api/account/finish-password-recovery" }), data, {
                withCredentials: true
            });
        },

        postTrocarSenha(data) {
            return Axios.post(this.CriarUrl({ resource: "api/account/change-password" }), data, {
                withCredentials: true
            });
        }
    }
};

export default RedefinirSenhaServiceMixin;
