import Axios from "axios";

var LoginServiceMixin = {
    methods: {
        postLogin(data) {
            return Axios.post(this.CriarUrl({ resource: "api/token" }), data, { withCredentials: true });
        },

        getTermoAtivo() {
            return Axios.get(this.CriarUrl({ resource: "api/termo-usos/ativo" }), { withCredentials: true });
        },

        postLogout() {
            return Axios.post(this.CriarUrl({ resource: "api/logout" }), null, { withCredentials: true });
        }
    }
};

export default LoginServiceMixin;
