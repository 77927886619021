<template>
    <unicom-modal v-model="mostrar" :largura="452" @input="valor => fecharModal(valor)" class="modal-erro">
        <div slot="header">
            <unicom-linha :justificarItem="'center'">
                <unicom-coluna :md="2" class="linha-icone">
                    <unicom-icone :nome="'alert-circle'" :tamanho="60" />
                </unicom-coluna>
            </unicom-linha>
            <h3 class="fonte-primaria negrito">Oops, algo deu errado.</h3>
        </div>
        <div slot="body" class="container-corpo">
            <unicom-linha espacamento>
                <unicom-coluna :md="12">
                    <div class="fonte-secundaria conteudo-erro">
                        <p class="negrito">Código do erro: {{ erro.codigo }}</p>
                        <p class="normal" style="white-space: pre-line;">{{ erro.descricao }}</p>

                        <div v-if="contemDetalhes">
                            <p
                                class="normal"
                                style="white-space: pre-line; line-break: anywhere;"
                                v-for="(item, i) in erro.detalhes"
                                :key="i"
                            >
                                - {{ item }}
                            </p>
                        </div>
                    </div>
                </unicom-coluna>
            </unicom-linha>
        </div>
        <div slot="footer" class="footer">
            <unicom-linha espacamento justificarItem="center">
                <unicom-coluna :md="5">
                    <unicom-botao
                        class="fonte-secundaria texto"
                        :bloco="true"
                        :tipo="'importante'"
                        @click="fecharModal(false)"
                    >
                        Dispensar
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        erroObj: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            mostrar: this.value,
            erro: this.erroObj ? this.erroObj : { codigo: "NNN", descricao: "Descrição do erro" }
        };
    },

    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar", this.erro.codigo);
            }
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        },
        erroObj() {
            this.erro = this.erroObj;

            if (this.erro.codigo === "GIU-048") {
                let msgErros = this.erroObj.descricao.split(" - ");

                this.erro.descricao = msgErros[0];
                this.erro.detalhes = msgErros[1].split("; ");
            }

            if (this.erro.codigo === "SSO-000") {
                this.erro.descricao = "Sua sessão expirou, por favor faça o login novamente.";
            }
        }
    },
    computed: {
        contemDetalhes() {
            return this.erro.detalhes != null;
        }
    }
};
</script>

<style lang="scss">
.modal-erro {
    color: #60656c;

    .modal-mask {
        z-index: 999999999999999999999999999 !important;
    }

    .linha-icone {
        margin-top: 24px;
        color: #ed1651;
    }

    h3 {
        margin: 24px 0;
        text-align: center;
        font-size: 24px;
    }

    p {
        margin-bottom: 8px;
        font-size: 18px;
    }

    .conteudo-erro {
        box-sizing: border-box;
        width: 100%;
        height: 122px;
        padding: 16px;
        border-radius: 4px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f1f2f1;
    }
}
</style>
