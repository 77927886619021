<template>
    <div>
        <unicom-modal v-model="mostrar" :largura="500" fechar @input="valor => fecharModal(valor)">
            <div slot="header">
                <h3 class="fonte-primaria titulo negrito">Alterar meus dados</h3>
            </div>

            <div slot="body" class="container-corpo">
                <unicom-linha
                    :espacamento="false"
                    :justificarItem="'center'"
                    alinharItem="center"
                    class="fonte-secundaria headerDetalhes"
                >
                    <unicom-coluna :md="4">
                        <div class="container-imagem">
                            <div class="center">
                                <div class="perfil">
                                    <figure>
                                        <img class="imagem-perfil" :src="foto" v-if="foto" />
                                        <img
                                            class="imagem-perfil"
                                            src="../../../../../public/assets/img-vazia.png"
                                            v-else
                                        />

                                        <div class="incluirFoto">
                                            <input
                                                type="file"
                                                @input="inserirFoto($event)"
                                                accept=".jpg, .jpeg, .png"
                                                class="input-text"
                                                :value="inputArquivo"
                                            />

                                            <unicom-icone nome="camera" :tamanho="18" style="color:white;" />
                                            <div class="texto fonte-secundaria">enviar foto</div>
                                        </div>
                                    </figure>
                                </div>
                            </div>

                            <p class="fonte-secundario removerFoto" v-if="isFoto" @click="removerFoto">remover foto</p>
                        </div>
                    </unicom-coluna>
                </unicom-linha>

                <ValidationObserver ref="alterarDados">
                    <unicom-linha :espacamento="false" :justificarItem="'center'" class="detalhesOperacao negrito">
                        <unicom-coluna>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <unicom-campo-texto
                                    :titulo="'Telefone *'"
                                    :minCaracteres="11"
                                    :apenasNumero="true"
                                    :mensagemErro="errors[0]"
                                    :mask="['(##) ####-####', '(##) #####-####']"
                                    v-model="telefone"
                                />
                            </validation-provider>
                        </unicom-coluna>

                        <unicom-coluna>
                            <validation-provider rules="email|required" mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto :titulo="'E-mail *'" :mensagemErro="errors[0]" v-model="email" />
                            </validation-provider>
                        </unicom-coluna>

                        <unicom-coluna style="margin: 30px 0">
                            <unicom-botao
                                :texto="'ALTERAR MINHA SENHA'"
                                :contornado="true"
                                :bloco="true"
                                @click="switchModal"
                            />
                        </unicom-coluna>
                    </unicom-linha>

                    <unicom-linha :justificarItem="'between'">
                        <unicom-coluna :md="6" :alinhamento="'center'">
                            <unicom-botao :texto="'CANCELAR'" :contornado="true" @click="mostrar = false" />
                        </unicom-coluna>

                        <unicom-coluna :md="5">
                            <unicom-botao :tipo="botaoTipo" :texto="'SALVAR'" :contornado="true" @click="alterarDados" />
                        </unicom-coluna>
                    </unicom-linha>
                </ValidationObserver>
            </div>
        </unicom-modal>        
        <unicom-modal-loading v-model="carregamento" />
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ColaboradorServiceMixin from "../../../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import UsuarioServiceMixin from "./../../../../mixins/services/usuario/UsuarioServiceMixin";

export default {
    mixins: [ColaboradorServiceMixin, UsuarioServiceMixin],
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            dadosUsuario: "",
            carregamento: false,
            genero: [this.value.genero],
            masculino: true,
            feminino: false,
            botaoDesabilitado: false,
            botaoTipo: "disableDark",
            telefone: "",
            email: "",

            foto: null,
            inputArquivo: null,
            arquivo: null
        };
    },
    async beforeMount() {
        await this.buscarFotoUsuario();
    },
    updated() {
        this.dadosUsuario = this.$store.getters.listarDadosCompleto;

        this.telefone = this.dadosUsuario.telefone;
        this.email = this.dadosUsuario.email;
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },

        async inserirFoto(e) {
            this.inputArquivo = null;
            this.arquivo = undefined;

            this.foto = null;
            let files = e.target.files || e.dataTransfer.files;
            this.arquivo = files[0];

            if (
                this.arquivo.type === "image/jpg" ||
                this.arquivo.type === "image/png" ||
                this.arquivo.type === "image/jpeg"
            ) {
                this.adicionarFoto();
            }
        },

        buscarFotoUsuario() {
            if (this.dadosUsuario.hasOwnProperty("arquivoResponse")) {
                this.carregamento = true;

                this.getFoto(this.dadosUsuario.arquivoResponse.uuid)
                    .then(response => {
                        let reader = new FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = () => {
                            reader.result;
                            this.foto = reader.result;
                        };
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.carregamento = false;
                    });
            }
        },

        async adicionarFoto() {
            await this.removerFoto();
            await this.adicionarFotoUsuario(this.arquivo);
        },

        removerFoto() {
            this.deletarFotoUsuario();
        },

        adicionarFotoUsuario(arquivo) {
            this.carregamento = true;
            this.patchAdicionarFoto(this.dadosUsuario.id, arquivo)
                .then(response => {
                    if (response.status === 200) {
                        this.foto = URL.createObjectURL(arquivo);
                        this.$emit('atualizar')
                    }
                })
                .finally(() => {
                    this.carregamento = false;
                });
        },

        deletarFotoUsuario() {
            this.carregamento = true;
            this.deleteFoto(this.dadosUsuario.id)
                .then(response => {
                    if (response.status === 200) {
                        this.foto = null;
                        this.$emit('atualizar')
                    }
                })
                .finally(() => {
                    this.carregamento = false;
                });
        },

        alterarDados() {
            this.$refs.alterarDados.validate().then(sucess => {
                if (sucess) {
                    this.dadosUsuario.telefone = this.telefone.replace(/[^0-9]+/g, "");

                    this.dadosUsuario.email = this.email;
                    this.$emit("loading", true);

                    this.putDadosBasicoMenu(this.dadosUsuario)
                        .then(response => {
                            if (response.status === 200) {
                                this.$emit("sucesso");
                                this.$emit("loading", false);
                                this.mostrar = false;
                            }
                        })
                        .catch(error => {
                            this.$emit("loading", false);
                            console.error(error);
                        });
                }
            });
        },
        switchModal() {
            this.$store.commit("switchModalAlterarSenha", true);
            this.mostrar = false;
        }
    },
    computed: {
        isFoto() {
            return this.foto !== null;
        },
        mostrar: {
            get() {
                return this.$store.getters.mostrarModalDados;
            },
            set(value) {
                return this.$store.commit("switchModalDados", value);
            }
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    margin-left: 12px;
    color: #00995d;

    span {
        color: #60656c;
    }
}

.corSecundaria {
    color: #00995d;
}

.perfil {
    figure {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        &:hover {
            background: #000000;
            & .incluirFoto {
                opacity: 1;
            }
        }
    }

    & .imagem-perfil {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: #dee2e6;
        border-radius: 50%;
        opacity: 1;
        transition: 0.5s ease;
        backface-visibility: hidden;
    }

    & .incluirFoto {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        cursor: pointer;
    }

    & .input-text {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        &:hover {
            cursor: pointer;
        }
    }

    & .texto {
        color: white;
    }
}

.perfil figure img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.perfil figure:hover img {
    opacity: 0.6;
}

.removerFoto {
    text-align: center;
    color: #1492e6;
    margin-top: 12px;
    cursor: pointer;
}

.textoImagem {
    margin-top: 16px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 16px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linha-conteudo {
    margin-top: 24px;

    .container-imagem {
        width: 100%;
        height: 268px;
        border: 1px solid #f1f2f1;
        border-radius: 8px;
        background-color: #f1f2f1;
    }

    .linha-dados {
        height: 90px;
        padding-top: 30px;
        padding-left: 12px;
        padding-bottom: 0;

        &.primeira-linha {
            padding-top: 0;
            padding-bottom: 30px;
            border-bottom: 1px solid #dee2e6;
        }

        .label-status {
            box-sizing: border-box;
            padding-bottom: 6px;
            height: 24px;
        }

        .campo-status {
            box-sizing: border-box;
            padding-top: 8px;
            height: 35px;

            .status {
                float: left;
                margin-top: 3px;
                margin-right: 8px;
                width: 12px;
                height: 12px;
                border-radius: 20px;
                background-color: #cccccc;
            }
        }

        .genero-checkbox {
            margin-top: 12px;
        }
    }
}
</style>
