<template>
    <div>
        <template v-if="!configJsonIsLoading">
            <notifications group="GIU" classes="vue-notification" />

            <unicom-cabecalho-v2
                @home="$router.push('/home')"
                v-if="showTheComponents()"
                :dropdownMenu="true"
                :fotoPerfil="fotoPerfilUsuario"
            >
                <template v-slot:dropdown-info>
                    <DropDownInfo :dadosSessao="dadosSessao" />
                </template>

                <template v-slot:dropdown-botoes>
                    <DropDownButton @click_termos="carregaTermo()" />
                </template>
            </unicom-cabecalho-v2>

            <unicom-navbar-v2 :titulo="titulo" :itens="getItens()" :caminhoHome="'/home'" v-if="showTheComponents()" />

            <main :class="showTheComponents() ? 'mainAcessoAutorizado' : ''">
                <router-view ref="router" @atualizar="atualizarSessao()" @alterarDados="alterarDados()"></router-view>
            </main>

            <footer v-if="showFooter()">
                <p><b>Versão: </b> {{ versao }}</p>
            </footer>

            <ModalErro v-model="mostrarErro" :erroObj="error" @fechar="erro => fecharErro(erro)" />

            <ModalTermoUso
                buttonText="fechar"
                :value="mostrarTermoUso"
                :edicao="false"
                :concordaTermo="true"
                :conteudo="termoDeUso.conteudo"
                @termo_fechar="mostrarTermoUso = false"
            />

            <unicom-modal :value="loading" :largura="420">
                <div slot="body">
                    <unicom-loading v-model="loading" style="margin-top:25px;" mensagem="Carregando Termos de Uso..." />
                </div>
            </unicom-modal>

            <unicom-modal-loading :value="$store.getters.loading" />

            <ModalAlterarSenha @fechar="$store.commit('switchModalAlterarSenha', false)" />

            <ModalAlterarDados
                v-model="mostrarModalAlterarDados"
                @sucesso="mostrarSucesso = true"
                @loading="value => (loading = value)"
                @fechar="mostrarModalAlterarDados = false"
                @atualizar="$emit('atualizar')"
            />
        </template>
    </div>
</template>

<script>
import Axios from "axios";

import { version } from "../package.json";

import UsuarioServiceMixin from "./mixins/services/usuario/UsuarioServiceMixin";

import DropDownButton from "./components/app/dropdown/DropDownButton";
import DropDownInfo from "./components/app/dropdown/DropDownInfo";

import ModalTermoUso from "./components/shared/modalTermoUso/ModalTermoUso";
import ModalErro from "./components/shared/tratamentoErro/modalErro/ModalErro";
import ModalAlterarSenha from "@/components/shared/modalAlterarSenha/ModalAlterarSenha";
import ModalAlterarDados from "@/components/home/shared/ModalAlterarDados/ModalAlterarDados";

import ColaboradorServiceMixin from "@/mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import PessoaJuridicaMixinService from "@/components/gerenciarUsuarios/shared/services/PessoaJuridicaMixinService";

export default {
    components: { ModalErro, ModalTermoUso, ModalAlterarSenha, ModalAlterarDados, DropDownButton, DropDownInfo },
    mixins: [UsuarioServiceMixin, ColaboradorServiceMixin, PessoaJuridicaMixinService],

    data() {
        return {
            configJsonIsLoading: true,
            loading: false,
            titulo: "Gestão Institucional Unimed",

            mostrarErro: false,
            mostrarModalAlterarDados: false,
            error: {},

            mostrarTermoUso: false,
            termoDeUso: {},
            dadosSessao: {},

            foto: null,
            versao: ""
        };
    },

    created() {
        const that = this;

        Axios.get("/assets/config.json")
            .then(res => {
                that.$appConfig = { config: res.data };
                that.configJsonIsLoading = false;

                that.StartBaseService();
                this.atualizarFotoPerfil();
            })
            .catch(err => console.error(err));

        Axios.get("/assets/version.txt")
            .then(res => (this.versao = res.data))
            .catch(() => (this.versao = version));

        this.atualizarSessaoApp();
    },
    
    methods: {
        alterarDados() {
            this.mostrarModalAlterarDados = true;
        },
        async atualizarSessaoApp() {
            if (this.$router.currentRoute.path !== "/login" || this.$router.currentRoute.path !== "/") {
                this.dadosSessao = await this.getSession();
            }
        },
        async atualizarSessao() {
            this.dadosSessao = await this.getSession();
            this.atualizarFotoPerfil();
        },
        showTheComponents() {
            return (
                this.$router.currentRoute.path !== "/login" &&
                this.$router.currentRoute.path !== "/recuperar-senha" &&
                this.$router.currentRoute.path !== "/gerar-nova-senha" &&
                this.$router.currentRoute.path !== "/"
            );
        },
        showFooter() {
            return (
                this.$router.currentRoute.path == "/" ||
                this.$router.currentRoute.path == "/login" ||
                this.$router.currentRoute.path == "/home"
            );
        },
        getItens() {
            const icones = [{ icone: "monitor", titulo: "Suas aplicações", url: "aplicacoes" }];

            if (this.getSession().usuarioUnimed.perfil.id === 1) {
                icones.push({ icone: "users", titulo: "Gerenciar usuários", url: "gerenciarUsuarios" });
            } else if (this.getSession().usuarioUnimed.perfil.id === -1) {
                icones.push(
                    { icone: "users", titulo: "Gerenciar usuários", url: "gerenciarUsuarios" },
                    { icone: "monitor", titulo: "Gerenciar aplicações", url: "gerenciarAplicacoes" },
                    { icone: "monitor", titulo: "Gerenciar conta serviço", url: "contaServico" },
                    { icone: "clipboard", titulo: "Auditoria", url: "auditoria" },
                    { icone: "bell", titulo: "Publicar boletim" }
                );
            }

            icones.push({ icone: "book", titulo: "Manuais", url: "manual" });

            return icones;
        },

        // Modal Error
        montarErroModal(err) {
            if (this.$route.path != "/" && this.$route.path != "/login") {
                this.error = err;
                this.mostrarErro = true;
            }
        },
        fecharErro(codigo) {
            this.mostrarErro = false;

            if (codigo === "SSO-000") {
                localStorage.removeItem("Authorization");
                localStorage.removeItem("tokensExpiry");
                this.$router.push("/");
            }
        },

        async carregaTermo() {
            this.loading = true;

            await this.getTermoAceito()
                .then(res => {
                    this.termoDeUso = res.data;
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    this.loading = false;
                    this.mostrarTermoUso = true;
                });
        },

        atualizarFotoPerfil() {
            this.getDadosBasicosMenu()
                .then(response => {
                    this.guardaFotoUsuario(response.data);
                })
                .catch(() => {});
        },
        guardaFotoUsuario(usuario) {
            if (usuario.foto) {
                this.getFoto(usuario.foto.uuid).then(response => {
                    let reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = () => {
                        reader.result;
                        this.foto = reader.result;
                    };
                });
            } else {
                this.foto = "/assets/img-vazia.png";
            }
        }
    },
    computed: {
        fotoPerfilUsuario() {
            return this.foto ? this.foto : "/assets/img-vazia.png";
        }
    }
};
</script>

<style lang="scss">
main {
    overflow-x: hidden;
    background-color: #f1f2f1;
}

footer {
    position: absolute;
    bottom: 10px;
    right: 40px;

    color: #60656c;
}

.mainAcessoAutorizado {
    position: absolute;
    top: 60px;
    left: 60px;
    box-sizing: border-box;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
}

.vue-notification {
    padding: 10px;
    margin: 0 15px 15px;
    margin-top: 30px;

    font-family: "Trebuchet MS";

    color: #ffffff;
    background: #44a4fc;
    border-left: 16px solid #187fe7;
    height: 80px;
    .notification-title {
        margin-top: 8px;
        margin-bottom: 16px;
        font-size: 16px;
    }

    .notification-content {
        font-size: 14px;
    }

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        background: #e54d42;
        border-left-color: #b82e24;
    }

    &.success {
        background: #68cd86;
        border-left-color: #42a85f;
    }
}

@media only screen and (max-width: 575px) {
    .mainAcessoAutorizado {
        position: absolute;
        top: 40px;
        left: 0px;
        box-sizing: border-box;
        width: 100vw;
        height: 100vh;
    }
}
</style>
