import Axios from "axios";

var UnimedServiceMixin = {
    methods: {
        setUnimedsList(unimeds) {
            localStorage.setItem("unimeds", JSON.stringify(unimeds));
        },
        getUnimedsList() {
            return JSON.parse(localStorage.getItem("unimeds"));
        },

        getUnimeds() {
            return Axios.get(this.CriarUrl({ resource: "api/unimeds" }));
        },

        getTodosEstados() {
            return Axios.get(this.CriarUrl({ resource: "api/dominio/todos-estados" }));
        },

        getTiposColaboradores() {
            return Axios.get(this.CriarUrl({ resource: "api/dominio/tipos-colaborador" }), { withCredentials: true });
        },
        getTiposUsuarios() {
            return Axios.get(this.CriarUrl({ resource: "api/dominio/tipos-usuario" }), { withCredentials: true });
        }
    }
};

export default UnimedServiceMixin;
