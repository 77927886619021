import Axios from "axios";

var ColaboradorServiceMixin = {
    methods: {
        getColaborador(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/colaboradores/filtra",
                    params: args
                }),
                { withCredentials: true }
            );
        },

        getAllColaborador(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/colaboradores/combo",
                    params: args
                }),
                { withCredentials: true }
            );
        },

        getColaboradorPeloId(id) {
            return Axios.get(this.CriarUrl({ resource: `api/colaboradores/${id}` }), { withCredentials: true });
        },

        getColaboradoresExportar(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/colaboradores/exportar",
                    params: args
                }),
                { withCredentials: true, responseType: "blob" }
            );
        },

        putAtualizarColaborador(id, body) {
            return Axios.put(this.CriarUrl({ resource: `api/usuario-unimed/${id}` }), body, { withCredentials: true });
        },

        putDadosProfissionais(id, body) {
            return Axios.put(this.CriarUrl({ resource: `api/colaboradores/dados-profissionais/${id}` }), body, {
                withCredentials: true
            });
        },

        postCadastrarColaborador(body) {
            return Axios.post(this.CriarUrl({ resource: `api/colaboradores` }), body, { withCredentials: true });
        },

        putImportarCargaColaborador(data) {
            return Axios.put(this.CriarUrl({ resource: `api/colaboradores/importar` }), data, {
                withCredentials: true
            });
        },
        deleteInativarColaborador(id) {
            return Axios.delete(
                this.CriarUrl({
                    resource: `api/colaboradores/${id}`
                }),
                { withCredentials: true }
            );
        }
    }
};

export default ColaboradorServiceMixin;
