<template>
    <unicom-modal v-model="mostrar" :largura="720" @input="valor => fecharModal(valor)" class="modal">
        <div v-if="edicao" slot="header">
            <h3 class="fonte-primaria titulo negrito">Bem vindo(a) ao novo Gestão Institucional</h3>
            <p class="fonte-secundaria">
                Este é seu primeiro acesso. Para continuar, é necessário concordar com os termos abaixo.
            </p>
        </div>
        <div v-else slot="header">
            <h3 class="fonte-primaria titulo negrito">Termos de Uso</h3>
        </div>

        <div slot="body" class="container-corpo">
            <unicom-linha :espacamento="false" justificarItem="center">
                <unicom-coluna :md="11">
                    <div class="scroll-termo">
                        <div class="texto-termo fonte-secundaria" v-html="conteudo"></div>
                    </div>
                </unicom-coluna>
            </unicom-linha>

            <unicom-checkbox class="checkbox-termo" v-model="aceitoTermos" :inativo="!edicao" unico />
            <p style="text-align: left" class="checkbox-texto fonte-secundaria texto">
                Li e concordo com os termos de uso do Gestão Institucional Unimed.
            </p>
        </div>

        <div slot="footer" class="footer" style="margin-top: 24px">
            <unicom-linha :espacamento="false" justificarItem="end">
                <unicom-coluna :md="3">
                    <unicom-botao
                        class="fonte-secundaria texto"
                        :bloco="true"
                        :desabilitado="!aceitoTermos"
                        @click="$emit('termo_' + buttonText)"
                    >
                        {{ buttonText.toUpperCase() }}
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        buttonText: {
            type: String,
            required: false,
            default: "continuar"
        },
        conteudo: {
            type: String,
            required: false
        },
        edicao: {
            type: Boolean,
            required: false,
            default: true
        },
        concordaTermo: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mostrar: this.value,
            aceitoTermos: this.concordaTermo
        };
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        },
        concordaTermo() {
            this.aceitoTermos = this.concordaTermo;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    margin-top: 35px;
    color: #00995d;
    font-size: 30px;
    margin-bottom: 24px;
    text-align: center;
}

p {
    text-align: center;
    color: #60656c;
    margin-bottom: 24px;
}

.texto-vermelho {
    color: #f0666f;
}

.checkbox-termo {
    margin-left: 26px;
    margin-top: 16px;
    white-space: pre;
    float: left;
}

.checkbox-texto {
    min-width: 100px;
    margin: 19px 6px 8px 60px;
    color: #60656c;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.scroll-termo {
    height: 216px;
    overflow-y: scroll;
    max-width: 590px;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.texto-termo {
    border: 1px solid #f1f2f1;
    padding-top: 17px;
    padding-right: 25px;
    padding-left: 24px;
    padding-bottom: 17px;
    border-radius: 5px;
    max-width: 520px;
    color: #60656c;
}

@media only screen and (max-width: 500px) {
    .checkbox-texto {
        margin-top: 12px;
    }
}
</style>
