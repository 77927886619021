export default {
    state: {
        loading: false
    },
    mutations: {
        setLoading (state, _loading) {
            state.loading = _loading
        }
    },
    getters: {
        loading: state => state.loading
    }
}