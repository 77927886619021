/* eslint-disable */
import { extend } from "vee-validate";
import {
    required,
    email,
    max,
    min,
    alpha,
    alpha_num,
    numeric,
    alpha_dash,
    alpha_spaces
} from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "O campo é obrigatório"
});

extend("email", {
    ...email,
    message: "Email inválido"
});

extend("telRequired", {
    validate: value => {
        const length = value.length;

        if (!length) return;

        return (
            length === 14 || length === 15 || ((value.startsWith("0800") || value.startsWith("3000")) && length === 13)
        );
    },
    message: "O telefone deve ser completo"
});

extend("alpha", {
    ...alpha,
    message: "Esse campo só pode conter letras"
});

extend("nomeAplicacao", {
    validate: value => {
        const regra = /[a-zA-ZzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9. ]/;
        return regra.test(value);
    },
    message: "Insira um nome de aplicação válido"
});

extend("url", {
    validate: value => {
        const regra1 = /^https:?\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/;
        const regra2 = /^http:?\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/;
        if(regra1.test(value)){
            return true;
        }else{
            return regra2.test(value);
        }
    },
    message: "Insira uma url válida (Ex.: https://endereço.com.br)"
});

extend("cpf", {
    validate: value => {
        value = value.replace(/([.-])/g, "");

        let soma = 0;
        let resto = 0;

        if (
            value == "00000000000000" ||
            value == "11111111111111" ||
            value == "22222222222222" ||
            value == "33333333333333" ||
            value == "44444444444444" ||
            value == "55555555555555" ||
            value == "66666666666666" ||
            value == "77777777777777" ||
            value == "88888888888888" ||
            value == "99999999999999"
        )
            return false;

        for (let i = 1; i <= 9; i++) soma = soma + parseInt(value.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(value.substring(9, 10))) return false;

        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(value.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(value.substring(10, 11))) return false;
        return true;
    },
    message: "Insira uma CPF válido"
});

extend("cnpj", {
    validate: value => {
        value = value.replace(/[^\d]+/g, "");

        if (value == "") return false;

        if (value.length != 14) return false;

        if (
            value == "00000000000000" ||
            value == "11111111111111" ||
            value == "22222222222222" ||
            value == "33333333333333" ||
            value == "44444444444444" ||
            value == "55555555555555" ||
            value == "66666666666666" ||
            value == "77777777777777" ||
            value == "88888888888888" ||
            value == "99999999999999"
        )
            return false;

        let tamanho = value.length - 2;
        let numeros = value.substring(0, tamanho);
        let digitos = value.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(0)) return false;

        tamanho = tamanho + 1;
        numeros = value.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(1)) return false;

        return true;
    },
    message: "Insira uma cnpj válido"
});

extend("dateValidator", {
    validate: value => {
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
            return false;
        }

        var parts = value.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        if (year < 1900 || year > 2050 || month === 0 || month > 12) {
            return false;
        }

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    },
    message: "Data inválida"
});

extend("novaSenha", {
    params: ["target"],
    validate(value, { target }) {
        return value === target.replace(" ", "");
    },
    message: "O campo deve ser igual a nova senha"
});

extend("validacaoDataPassada", {
    validate: value => {
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
            return false;
        }

        var parts = value.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        let myDate = new Date(year, month - 1, day);
        let dateNow = new Date();

        if (myDate > dateNow == true) {
            return false;
        }

        if (year < 1900 || year > 2050 || month === 0 || month > 12) {
            return false;
        }

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    },
    message: "Data invalida"
});

extend("dateValidatorForFuture", {
    validate: value => {
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
            return false;
        }

        var parts = value.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        let myDate = new Date(year, month - 1, day);
        let dateNow = new Date();

        if (myDate < dateNow == true) {
            return false;
        }

        if (year < 1900 || year > 2050 || month === 0 || month > 12) {
            return false;
        }

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    },
    message: "Data invalida"
});

extend("yearValidatorForPast", {
    validate: value => {
        var year = parseInt(value);
        let dateNow = new Date();

        if (year < 1900 || year > dateNow.getFullYear()) {
            return false;
        } else {
            return true;
        }
    },
    message: "Ano inválido"
});

extend("yearAndMonthValidator", {
    validate: value => {
        if (value === null || value === undefined || value === "") {
            return true;
        } else {
            var parts = value.split("/");

            var day = 1;
            var month = parseInt(parts[0], 10);
            var year = parseInt(parts[1], 10);

            let myDate = new Date(year, month - 1, day);
            let dateNow = new Date();

            if (myDate > dateNow == true) {
                return false;
            }

            if (year < 1900 || year > 2050 || month === 0 || month > 12) {
                return false;
            }

            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
                monthLength[1] = 29;
            }

            return day > 0 && day <= monthLength[month - 1];
        }
    },
    message: "Data invalida"
});

extend("validateDataFinal", {
    params: ["target"],
    validate(value, { target }) {
        if (value && target && target.length === 9 && value.length === 7) {
            var partsFinal = value.split("/");
            var dayFinal = 1;
            var monthFinal = parseInt(partsFinal[0], 10);
            var yearFinal = parseInt(partsFinal[1], 10);

            var partsInicial = target.split("/");
            var dayInicial = 1;
            var monthInicial = parseInt(partsInicial[0], 10);
            var yearInicial = parseInt(partsInicial[1], 10);

            let dataInicial = new Date(yearInicial, monthInicial - 1, dayInicial);
            let dataFinal = new Date(yearFinal, monthFinal - 1, dayFinal);

            if (dataInicial <= dataFinal) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
    message: "Data inicial maior que data final"
});

extend("validatePrazoValidade", {
    params: ["target"],
    validate(value, { target }) {
        if (value && target && target.length === 11 && value.length === 10) {
            var partsFinal = value.split("/");
            var dayFinal = parseInt(partsFinal[0], 10);
            var monthFinal = parseInt(partsFinal[1], 10);
            var yearFinal = parseInt(partsFinal[2], 10);

            var partsInicial = target.split("/");
            var dayInicial = parseInt(partsInicial[0], 10);
            var monthInicial = parseInt(partsInicial[1], 10);
            var yearInicial = parseInt(partsInicial[2], 10);

            let dataInicial = new Date(yearInicial, monthInicial - 1, dayInicial);
            let dataFinal = new Date(yearFinal, monthFinal - 1, dayFinal);

            if (dataInicial <= dataFinal) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
    message: "Data inicial maior que data final"
});

extend("validateDataMandato", {
    params: ["target"],
    validate(value, { target }) {
        if (value && target && target.length === 12 && value.length === 10) {
            var partsFinal = value.split("/");
            var dayFinal = parseInt(partsFinal[0], 10);
            var monthFinal = parseInt(partsFinal[1], 10);
            var yearFinal = parseInt(partsFinal[2], 10);

            var partsInicial = target.split("/");
            var dayInicial = parseInt(partsInicial[0], 10);
            var monthInicial = parseInt(partsInicial[1], 10);
            var yearInicial = parseInt(partsInicial[2], 10);

            let dataInicial = new Date(yearInicial, monthInicial - 1, dayInicial);
            let dataFinal = new Date(yearFinal, monthFinal - 1, dayFinal);

            if (dataInicial <= dataFinal) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
    message: "Data inicial maior que data final"
});

extend("limitadorValidate", {
    validate: value => {
        return value > 0;
    },
    message: "Quantidade deve ser maior que 0"
});

extend("urlValidate", {
    validate: value => {
        return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value);
    },
    message: "Data invalida"
});

extend("validacaoNomeSobrenome", {
    validate: value => {
        return /^[A-ZÀ-Ÿa-z][A-Za-zÀ-ÿ']+\s([A-Za-zÀ-ÿ']\s?)*[A-Za-zÀ-Ÿ][A-za-zÀ-ÿ']+$/.test(value);
    },
    message: "Deve ser inserido nome e sobrenome"
});

extend("cnpjValidate", {
    validate: value => {
        var cnpj = value;

        cnpj = cnpj.replace(/[^\d]+/g, "");

        if (cnpj == "") return false;

        if (cnpj.length != 14) return false;

        if (
            cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999"
        )
            return false;

        var tamanho = cnpj.length - 2;
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(0)) return false;
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(1)) return false;

        return true;
    }
});

extend("alpha_num", {
    ...alpha_num,
    message: "Não pode conter esse caracter"
});

extend("max", {
    ...max,
    message: "Tamanho do campo excedeu o limite."
});

extend("min", {
    ...min,
    message: "Tamanho minimo não atendido"
});

extend("numeric", {
    ...numeric,
    message: "Esse campo só pode conter números"
});

extend("alpha_dash", {
    ...alpha_dash,
    message: "Não pode conter esse caracter"
});

extend("alpha_spaces", {
    ...alpha_spaces,
    message: "Não pode conter esse caracter"
});

extend("teste", {
    validate: () => {
        return false;
    },
    message: "Teste"
});

extend("dateMaiorHoje", {
    validate: value => {
        let validDate = moment(value, "DD/MM/YYYY", true);
        let dataValidada = validDate.isValid();
        let dateBefore = validDate.isAfter();
        let year = validDate.year();

        if (!dateBefore && dataValidada) {
            return true;
        } else {
            return false;
        }
    },
    message: "Dia maior que hoje"
});
