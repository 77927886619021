export default {
    state: {
        aplicacoesPapeis: [],
        filtroAplicacoesSelecionadas: []
    },
    mutations: {
        adicionarListaAplicacoesPapeis(state, newAplicacoesPapeis) {
            state.aplicacoesPapeis = newAplicacoesPapeis;
        },
        aplicacacoesSelecionadas(state, newFiltroAplicacoesSelecionadas) {
            state.filtroAplicacoesSelecionadas = newFiltroAplicacoesSelecionadas;
        },
        inserirPapeisAplicacao(state, newListaPapeis) {
            let index = state.aplicacoesPapeis.findIndex(obj => obj.id === newListaPapeis.id);
            //Não sei porque mas quando commito ele chama duas vezes a mutation dando erro na segunda
            if (index === -1) return;
            Object.assign(state.aplicacoesPapeis[index], { papeisAtivos: newListaPapeis.lista.papeis });
        }
    },
    getters: {
        listaAplicacoesPapeis: state => state.aplicacoesPapeis,
        aplicacacoesSelecionadas: state => state.filtroAplicacoesSelecionadas,
        filtrarAplicacoesSelecionadas(state) {
            let filtro = [];
            state.filtroAplicacoesSelecionadas.forEach(id => {
                let index = state.aplicacoesPapeis.findIndex((el) => el.id === id)
                filtro.push(state.aplicacoesPapeis[index]);
            });
            return filtro;
        }
    },

    actions: {
        gerenciamentoEstadoAplicacoesSelecionadas({ state, commit }, item) {
            item.forEach(index => {
                if (!state.filtroAplicacoesSelecionadas.includes(index)) {
                    state.aplicacoesPapeis[index].papeisAtivos = [];
                }
            });
            commit("aplicacacoesSelecionadas", item);
        }
    }
};
