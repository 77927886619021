<template>
    <div>
        <unicom-modal v-model="mostrar" :largura="500" fechar @input="valor => fecharModal(valor)">
            <div slot="header">
                <h3 class="fonte-primaria titulo negrito">Alterar minha senha</h3>
            </div>

            <div slot="body">
                <div class="nova-senha-container">
                    <div class="container-imagem" />

                    <div class="container-nova-senha">
                        <div class="nova-senha">
                            <ValidationObserver ref="alterarSenha">
                                <unicom-linha espacamento class="linha-login">
                                    <unicom-coluna>
                                        <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                            <unicom-campo-texto
                                                v-model="senhaAtual"
                                                class="campo"
                                                :titulo="'Senha atual *'"
                                                :placeholder="'Insira a senha atual'"
                                                :mensagemErro="errors[0]"
                                                :tipoCampo="password_type['atual']"
                                            />
                                            <unicom-icone
                                                class="icone"
                                                :nome="password_icon['atual']"
                                                :tamanho="22"
                                                @click="toggleTypePassword('atual')"
                                            />
                                        </validation-provider>
                                    </unicom-coluna>
                                </unicom-linha>

                                <unicom-linha espacamento class="linha-login">
                                    <unicom-coluna>
                                        <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                            <unicom-campo-texto
                                                class="campo"
                                                v-model="senhaNova"
                                                :titulo="'Nova Senha *'"
                                                :placeholder="'Insira sua nova senha'"
                                                :mensagemErro="errors[0]"
                                                :tipoCampo="password_type['nova']"
                                            />
                                            <unicom-icone
                                                class="icone"
                                                :nome="password_icon['nova']"
                                                :tamanho="22"
                                                @click="toggleTypePassword('nova')"
                                            />
                                        </validation-provider>
                                    </unicom-coluna>
                                </unicom-linha>

                                <unicom-linha espacamento class="linha-login">
                                    <unicom-coluna>
                                        <p class="fonte-secundaria texto">A nova senha deve conter:</p>
                                        <div
                                            class="lista-validacao"
                                            v-for="(item, index) in listaValidacao"
                                            :key="index"
                                        >
                                            <div :class="`icone-container ${validacaoConcluida(index)}`">
                                                <unicom-icone :nome="'check-circle'" :tamanho="14" />
                                            </div>
                                            <div
                                                :class="
                                                    `fonte-secundaria texto label-container ${validacaoConcluida(
                                                        index
                                                    )}`
                                                "
                                            >
                                                {{ item.descricao }}
                                            </div>
                                        </div>
                                    </unicom-coluna>
                                </unicom-linha>

                                <unicom-linha espacamento class="linha-login" justificarItem="between">
                                    <unicom-coluna :md="5">
                                        <unicom-botao :texto="'CANCELAR'" :contornado="true" @click="mostrar = false" />
                                    </unicom-coluna>

                                    <unicom-coluna :md="5">
                                        <unicom-botao tipo="primario" @click="continuar()" :desabilitado="liberarBotao">
                                            ALTERAR SENHA
                                        </unicom-botao>
                                    </unicom-coluna>
                                </unicom-linha>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </unicom-modal>
        <unicom-modal-sucesso
            v-model="mostrarModalSucesso"
            titulo="A senha foi alterada com sucesso!"
            @fechar="mostrarModalSucesso = false"
        />
    </div>
</template>

<script>
import RedefinirSenhaServiceMixin from "../../../mixins/services/redefinirSenha/RedefinirSenhaServiceMixin";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    mixins: [RedefinirSenhaServiceMixin],

    data() {
        return {
            mostrarModalSucesso: false,

            senhaAtual: "",
            senhaNova: "",
            usuario: "",

            listaValidacao: [],

            password_icon: {
                atual: "eye",
                nova: "eye"
            },

            password_type: {
                atual: "password",
                nova: "password"
            }
        };
    },

    beforeMount() {
        this.getListaValidacao();
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.senhaAtual = "";
                this.senhaNova = "";

                this.$emit("fechar");
            }
        },
        getListaValidacao() {
            this.getRegrasValidacao().then(response => {
                this.listaValidacao = response.data;
            });
        },
        validacaoConcluida(index) {
            let regra = new RegExp(this.listaValidacao[index].regra.slice(1, -1));
            return regra.test(this.senhaNova) ? "ativo" : "";
        },
        async continuar() {
            let validacaoNovaSenha = await this.$refs.alterarSenha.validate();
            if (validacaoNovaSenha) {
                this.novaSenha();
            }
        },
        novaSenha() {
            this.postTrocarSenha(this.montarNovaSenha()).then(() => {
                this.mostrarModalSucesso = true;
                this.fecharModal();
            });
        },
        montarNovaSenha() {
            let usuario = this.getSession().login;
            return {
                username: usuario,
                oldPassword: this.senhaAtual,
                newPassword: this.senhaNova
            };
        },
        toggleTypePassword(campo) {
            if (this.toggle) {
                this.password_icon[campo] = "eye-off";
                this.password_type[campo] = "text";
            } else {
                this.password_icon[campo] = "eye";
                this.password_type[campo] = "password";
            }

            this.toggle = !this.toggle;
        }
    },
    computed: {
        titulo() {
            return !this.sucesso ? "Crie uma nova senha" : "Nova senha criada!";
        },
        liberarBotao() {
            let erro = [];
            this.listaValidacao.forEach(item => {
                let regra = new RegExp(item.regra.slice(1, -1));
                erro.push(!(regra.test(this.senhaNova) && this.senhaAtual != "" && this.senhaNova != ""));
            });
            return erro.includes(true);
        },
        mostrar: {
            get() {
                return this.$store.getters.mostrarModalAlterarSenha;
            },
            set(value) {
                return this.$store.commit("switchModalAlterarSenha", value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #00995d;
}
.nova-senha-container {
    .container-nova-senha {
        .nova-senha {
            .linha-icone {
                color: #bebebe;
                height: 24px;
                margin-bottom: 12px;

                .icone-container {
                    display: block;
                    float: left;
                    width: 24px;
                    cursor: pointer;
                }

                .label-container {
                    display: block;
                    float: left;
                    width: calc(100% - 24px);

                    p {
                        color: #bebebe;
                        margin-top: 3px;
                        margin-left: 16px;
                        cursor: pointer;
                    }
                }
            }

            h2 {
                font-size: 32px;
                line-height: 55px;
                color: #00995d;
                padding-left: 14px;
            }

            p {
                margin: 0;
                color: #60656c;
            }

            .linha-checkbox {
                margin-bottom: 24px;
            }

            .linha-login {
                margin-top: 24px;

                &.primeira-linha {
                    margin-top: 0;
                }

                .icone {
                    position: absolute;
                    top: 33px;
                    right: 25px;
                    cursor: pointer;
                    color: #707070;
                }

                .lista-validacao {
                    margin: 14px 0;
                    box-sizing: border-box;
                    height: 16px;
                    color: #60656c;

                    &:last-child {
                        margin: 0;
                    }

                    .icone-container {
                        float: left;
                        box-sizing: border-box;
                        width: 16px;
                        height: 16px;

                        &.ativo {
                            color: #00995d;
                        }
                    }

                    .label-container {
                        float: left;
                        margin-left: 6px;
                        box-sizing: border-box;
                        width: calc(100% - 22px);

                        &.ativo {
                            text-decoration: line-through;
                            color: #00995d;
                        }
                    }
                }
            }

            small {
                margin-left: 16px;
                color: #1492e6;
                cursor: pointer;
            }
        }

        .sucesso {
            h2 {
                font-size: 32px;
                line-height: 55px;
                color: #00995d;
                padding-left: 14px;
            }

            p {
                margin: 0;
                color: #60656c;
                padding-left: 14px;
            }
        }
    }
}
</style>
