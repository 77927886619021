export default {
    state: {
        dadosCompleto: {}
    },
    getters: {
        listarDadosCompleto: function(state) {
            return state.dadosCompleto;
        }
    },
    mutations: {
        adicionarDadosCompleto(state, dadosCompleto) {
            state.dadosCompleto = dadosCompleto;
        }
    },
    actions: {}
};
